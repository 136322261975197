import { BenefitType } from '@wix/ambassador-pricing-plans-v2-benefit/types';
import { PaymentOption } from '../../../../types/types';
import { SlotService, SlotServices } from '../../../state/types';
import { mapToArray } from '../../..';

export const getExceedLimitedPricePlans = (
  slotServices: SlotServices,
): PaymentOption[] => {
  const services = mapToArray<SlotService>(slotServices);

  const creditsRemain: { [key: string]: number } = {};

  const paymentOptions = services
    .map((service) => service.selectedPaymentOption)
    .filter(
      (paymentOption) =>
        paymentOption?.benefitInfo?.benefit?.benefitType ===
        BenefitType.LIMITED,
    );

  paymentOptions.forEach((option) => {
    if (creditsRemain[option.id] !== undefined) {
      creditsRemain[option.id] -= 1;
    } else {
      creditsRemain[option.id] = option.creditRemain! - 1;
    }
  });

  const exceededOptions: PaymentOption[] = [];
  Object.keys(creditsRemain).forEach((key) => {
    if (creditsRemain[key] < 0) {
      const option = paymentOptions.find((opt) => opt?.id === key);
      if (option) {
        exceededOptions.push(option);
      }
    }
  });

  return exceededOptions.length > 0 ? exceededOptions : [];
};
