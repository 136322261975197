import {
  PaymentMethod,
  PaymentOption,
  PaymentType,
  ReservedPaymentOptionLabels,
} from '../../../../types/types';

export const sortPaymentOptions = (
  paymentOptions: PaymentOption[],
  sortOrder: PaymentMethod,
): PaymentOption[] => {
  const plansOptions = [
    ...paymentOptions.filter((p) => p.type === PaymentType.USE_PLAN),
    ...paymentOptions.filter((p) => p.type === PaymentType.BUY_PLAN),
  ];

  const onlineOption = paymentOptions.filter(
    (p) =>
      p.type === PaymentType.SINGLE_SESSION &&
      p.label === ReservedPaymentOptionLabels.ONLINE,
  );
  const offlineOptions = paymentOptions.filter(
    (p) =>
      p.type === PaymentType.SINGLE_SESSION &&
      p.label !== ReservedPaymentOptionLabels.ONLINE,
  );

  switch (sortOrder) {
    case PaymentMethod.ONLINE: {
      return [...onlineOption, ...offlineOptions, ...plansOptions];
    }
    case PaymentMethod.OFFLINE: {
      return [...offlineOptions, ...onlineOption, ...plansOptions];
    }
    case PaymentMethod.MEMBERSHIP: {
      return [...plansOptions, ...onlineOption, ...offlineOptions];
    }
    default: {
      return [...onlineOption, ...offlineOptions, ...plansOptions];
    }
  }
};
