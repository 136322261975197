import React, { useMemo, useEffect } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { ServiceData, SlotServices } from '../../../../utils/state/types';
import { FormStatus } from '../../../../types/form-state';
import { BuyPlanButton } from './BuyPlanButton';
import { BookButton } from './BookButton';
import { AddCartButton } from './AddCartButton';
import { useFormComponentContext } from '../../Hooks/useFormComponentContext';
import { CreateBookingErrorType, FormError } from '../../../../types/errors';
import {
  checkDifferentBuyPlans,
  getExceedLimitedPricePlans,
  getSelectedServicesForBuyPlan,
} from '../../../../utils/payment';
import {
  SectionMessage,
  SectionMessageType,
} from '../SectionMessage/SectionMessage';
import { classes } from './ButtonContainer.st.css';
import { getErrorByType } from '../../../../utils/errors/errors';
import { CartFlow } from '../../../../types/types';
import { useFormActions } from '../../Hooks/useFormActions';

export interface ButtonContainerProps {
  serviceData: ServiceData;
  status: FormStatus;
  errors: FormError[];
  isCart?: boolean;
  isServiceInCart?: boolean;
}

interface AlertMessageProps {
  slotServices: SlotServices;
}

interface ErrorMessageProps {
  errors: FormError[];
}

const ButtonsContainer: React.FC<ButtonContainerProps> = ({
  serviceData,
  isCart,
  status,
  isServiceInCart,
  errors,
}) => {
  const { isUpsellPluginInstalled } = useFormComponentContext();

  const slotServicesWithBuyPlans = getSelectedServicesForBuyPlan(
    serviceData.slotServices,
  );
  const exceedLimitedPricePlans = getExceedLimitedPricePlans(
    serviceData.slotServices,
  );
  let buttons;

  if (slotServicesWithBuyPlans.length || exceedLimitedPricePlans.length) {
    buttons = (
      <BuyPlanButton slotServices={serviceData.slotServices} status={status} />
    );
  } else if (isCart) {
    buttons = (
      <>
        <AddCartButton status={status} />
        {!isServiceInCart && !isUpsellPluginInstalled && (
          <BookButton
            slotServices={serviceData.slotServices}
            cartFlow={CartFlow.CHECKOUT}
            status={status}
            primary={false}
          />
        )}
      </>
    );
  } else {
    buttons = (
      <BookButton slotServices={serviceData.slotServices} status={status} />
    );
  }

  return (
    <div className={classes.root}>
      <RenderAlertMessage slotServices={serviceData.slotServices} />
      <RenderErrorMessage errors={errors} />
      <div className={classes.buttons}>{buttons}</div>
    </div>
  );
};

const RenderAlertMessage: React.FC<AlertMessageProps> = ({ slotServices }) => {
  const { t } = useTranslation();
  const { onConflictAlertShown } = useFormActions();

  let alertText: string = '';

  const isDifferentBuyPlan = checkDifferentBuyPlans(slotServices);
  const exceedPlans = getExceedLimitedPricePlans(slotServices);

  if (exceedPlans?.length) {
    const planNames = exceedPlans.map((plan) => plan.label).join(', ');
    alertText = t(
      'app.bookings-form.right-side-alert.not-enough-sessions-left-price-plan',
      { planNames },
    );
  } else if (isDifferentBuyPlan) {
    alertText = t('app.bookings-form.right-side-alert.too-many-plans');
  }

  useEffect(() => {
    if (alertText) {
      onConflictAlertShown(alertText);
    }
  }, [alertText]);

  if (!isDifferentBuyPlan && !exceedPlans?.length) {
    return null;
  }

  return (
    <SectionMessage
      type={SectionMessageType.Alert}
      text={alertText}
      withoutIcon={true}
    />
  );
};

const RenderErrorMessage: React.FC<ErrorMessageProps> = ({ errors }) => {
  const { t } = useTranslation();

  const error = getErrorByType({
    errorType: CreateBookingErrorType,
    errors,
  });

  const getErrorMessage = useMemo(() => {
    if (error) {
      switch (error.errorType) {
        case CreateBookingErrorType.SESSION_CAPACITY_EXCEEDED:
        case CreateBookingErrorType.SCHEDULE_CAPACITY_EXCEEDED:
          return t('app.server-errors.not-enough-spots-left');
        case CreateBookingErrorType.VIOLATIONS_WITH_ERROR_SEVERITY:
          return error.params?.message;
        default:
          return t('app.server-errors.slot-not-available');
      }
    }
    return '';
  }, [error]);

  return (
    <SectionMessage type={SectionMessageType.Error} text={getErrorMessage} />
  );
};

export default ButtonsContainer;
